@use '@angular/material' as mat;
@import "common/breakpoints";


// When injecting a class to the CommonListRender, class must be global.
@mixin app-list-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .common-table-column-more-options {
        width: 35px;
    }

    .onboarding-form-table {
        display: block;
        height: 100%;
        max-height: 100%;
        overflow-y: hidden;

        .responsive-table {
            height: 100%;
            max-height: 100%;
            overflow-y: hidden;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        mat-table {
            height: 100%;
            max-height: 100%;
            overflow-y: auto;

            mat-header-row {
                background-color: #eee;

                mat-header-cell {
                    font-weight: bold;
                    font-size: 0.9em;
                    color: black;
                }
            }

            mat-row {
                mat-cell {
                    font-size: 0.9em;
                }
            }

            mat-footer-row {
                border-top: 2px solid #eee;
            }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
        mat-paginator {
            border: 2px solid #eee;
        }
    }
}

.common-list-toolbar-option {
    @include breakpoint-lte("sm") {
        flex-grow: 1;
    }
}

.app-common-list--mat-table-container {}

.app-common-list--vertical-scrolling {
    @extend .scrollable-vertical-parent;

    // Don't want the overflow to appear above the table header.
    --app-mdc-data-table--padding-top: 0px;
    --app-mdc-data-table--padding-bottom: 0px;

    .app-common-list--renderer-component {
        @extend .scrollable-vertical-parent;
    }

    app-common-list-default-display-mode {
        @extend .scrollable-vertical-parent;
    }

    .app-common-list--mat-table-container {
        @extend .scrollable-vertical-parent;
    }

    mat-table {
        overflow-y: auto;
    }
}

.app-common-list--plain-style {

}

.app-common-list--normal-style {
    &.app-common-list--renderer-component {
   
        mat-table {
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            border-top-style: solid;
            border-top-width: 1px;
            border-top-color: var(--app-container-border-color);
            border-left-style: solid;
            border-left-width: 1px;
            border-left-color: var(--app-container-border-color);
            border-right-style: solid;
            border-right-width: 1px;
            border-right-color: var(--app-container-border-color);
        }
    
        .mat-mdc-header-row {
            color: var(--app-common-list--header-row--color);
            background-color: var(--app-common-list--header-row--background-color);
            padding-right: 30px;
            padding-left: 30px;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
    
            border-style: solid;
            border-width: 1px;
            border-color: var(--app-container-border-color);
        }
    
        .mat-mdc-row {
    
            border-left-style: solid;
            border-left-width: 1px;
            border-left-color: var(--app-container-border-color);
    
            border-right-style: solid;
            border-right-width: 1px;
            border-right-color: var(--app-container-border-color);
    
            padding-right: 30px;
            padding-left: 30px;
    
            &:hover {
                background-color: var(--app-common-list--item-hover--background-color);
            }
        }
    
        .mat-mdc-table {
            border-width: 1px;
            border-color: var(--app-container-border-color);
    
            border-bottom-style: none;
        }
    
        app-common-list-default-display-mode {
            // mat-table {
            //     mat-header-row {
            //         width: max-content;
            //     }
    
            //     mat-row {
            //         width: max-content;
            //     }
    
            //     mat-footer-row {
            //         width: max-content;
            //     }
            // }
            mat-paginator {
                border-width: 1px;
                border-style: solid;
                border-color: var(--app-container-border-color);
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                border-top-style: none;
            }
        }
    }
}
