.app-property-list-container {
    //border: 1px solid var(--app-divider-color);
    //border-radius: 4px;

    //overflow: hidden;

    .list-item {
        border: 1px outset var(--app-divider-color);
        padding: 8px 8px 8px 16px;
        display: flex;
        flex-flow: row nowrap !important;
        align-items: center;
        box-sizing: border-box;
        width: 100%;

        //outline: 1px solid rgba(#000, 0.1);

        transition: background-color 0.25s linear;

        &:hover {
            background-color: var(--app-list-item-hover-background-color);
        }

        &[ng-reflect-router-link] {
            cursor: pointer;
        }
    }

    .list-item:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .list-item:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &.with-row-flow {
        display: flex;
        flex-flow: row wrap;

        .list-item:first-child {
            border-radius: 4px 0 0 0;
        }
        .list-item:last-child {
            border-radius: 0 0 4px 0;
        }
    }
}

.property-list {
    display: flex;
    flex-flow: column;
    padding: 8px 0;

    &.with-border-bottom {
        .value {
            border-bottom: 1px solid var(--app-divider-color);
        }

        .list-item:last-child .value {
            border-bottom: 0;
        }
    }

    .list-item {
        display: flex;
        flex-flow: row;
        align-items: center;
        padding: 0;
        margin: 0;

        .label {
            color: var(--app-secondary-text-color);
            font-size: 0.8em;
            flex: 0 1 50%;
            max-width: 140px;
            min-width: 140px;
            padding: 8px 16px 8px 8px;
            text-align: right;
        }

        .value {
            padding: 12px 16px 12px;
            border-left: 1px solid var(--app-divider-color);
            flex: 1 1 auto;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -8px;
                bottom: 0;
                width: 8px;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
            }

            // waiting for CSS4 to use :has()
            &.with-button {
                padding: 4px 16px 4px;
            }
        }

        @include breakpoint-lte("sm") {
            flex-flow: column;
            align-items: stretch;
            margin-bottom: 8px;

            .label {
                text-align: left;
                max-width: 100%;
            }
            .value {
                border-left: 0;
                padding: 0 8px 8px;

                &:before,
                &:after {
                    display: none;
                }

                &.with-button {
                    padding: 0 8px 8px;
                }
            }
        }
    }

    &.edit-overlay {
        padding: 0;
        width: 100%;

        background: var(--app-card-background-color);

        box-shadow: 0 0 24px 2px rgba(#000, 0.25);
        outline: 1px solid var(--app-list-item-outline-background-color);

        @include breakpoint-lte("sm") {
            padding-top: 8px;
        }

        .actions {
            padding-right: 8px;
            display: flex;
            flex-flow: row;
        }

        .value {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            //padding: 4px 16px 4px;

            input {
                margin: 0;
                padding: 1px 0 0 0;
                border: none;
                outline: none;
                border-bottom: 2px dotted #000;

                flex: 1 1 auto;
                font: inherit;
                line-height: 38px;
                text-align: inherit;
                color: currentColor;
                background: transparent;
                width: 100%;
                max-width: 100%;

                &:focus {
                    border-bottom: 2px solid var(--app-list-item-outline-background-color);
                }
            }

            mat-error {
                margin-top: 4px;
                font-size: 0.8em;
            }
        }

        .mat-icon-check {
            color: #4caf50;
        }

        .mat-icon-cancel {
            color: #de2f2f;
        }
    }
}

.property-list:not(.edit-overlay) {
    .value {
        display: flex;
        align-items: center;
    }
}