@import "./shape";

.extra-small-shape {
    @include apply-shape-size(extra-small);
}

.small-shape {
    @include apply-shape-size(small);
}

.medium-shape {
    @include apply-shape-size(medium);
}

.large-shape {
    @include apply-shape-size(large);
}

.extra-large-shape {
    @include apply-shape-size(extra-large);
}