@use '@angular/material' as mat;

// Compute card width to be used inside an app-form-container.
@function compute-card-width($expected-nb-card-per-line) {
    @return calc((var(--app-form-container--max-width) - ((#{$expected-nb-card-per-line} - 1) * var(--app-container-gap))) / #{$expected-nb-card-per-line});
}

@mixin create-default-card(
    $width: 325px, $height: 140px,
    $verticalCenterAll: false,
    $padding: null,
    $set-default-area: true
) {
    @if($width !=null) {
        width: $width;
        min-width: $width;
        max-width: $width;
    }

    @if($height !=null) {
        height: $height;
        min-height: $height;
        max-height: $height;
    }

    @if $verticalCenterAll {
        design-card-title {
            @include apply-vertical-align();
        }

        design-card-subtitle {
            @include apply-vertical-align();
        }

        design-card-content {
            @include apply-vertical-align();
        }

        design-card-header {
            @include apply-vertical-align();
        }

        design-card-footer {
            @include apply-vertical-align();
        }
    }


    @if $set-default-area {
        --app-design-card--grid-template-areas:
            'content';
    }

    @if $padding !=null {
        @if $padding {
            --app-design-card--padding-left: var(--app-design-card--default-padding);
            --app-design-card--padding-right: var(--app-design-card--default-padding);
            --app-design-card--padding-top: var(--app-design-card--default-padding);
            --app-design-card--padding-bottom: var(--app-design-card--default-padding);
        }

        @else {
            --app-design-card--padding-left: 0px;
            --app-design-card--padding-right: 0px;
            --app-design-card--padding-top: 0px;
            --app-design-card--padding-bottom: 0px;
        }
    }

    --app-mdc-card-content--color: var(--app-design-card--color);
    --mat-card-subtitle-text-color: var(--app-design-card--subtitle-text-color, var(--mat-card-subtitle-text-color));
    --mdc-outlined-card-container-color: var(--app-design-card--background-color);
    --mdc-elevated-card-container-color: var(--app-design-card--background-color);
}

