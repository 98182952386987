@use '@angular/material' as mat;
//@use '@angular/material-experimental' as matx;
@use 'sass:meta';
@import "mat-m3-shared";
@import "./settings";
@import "./palettes/test-palette";
@import "./palettes/test-palette1";
@import "./palettes/test-palette2";

// Documentation:
//    https://material.angular.io/guide/material-3#customizing-your-colors

// Plus imports for other components in your app.

// To disable legacy API compatibility, when the app will be fully migrated to theme inspection API.
//mat.$theme-legacy-inspection-api-compatibility: true;

// Global typography map.
$md-typography-config: (
    title-large: (
        font-weight: 600,
        font-size: 20px
    ),
    title-medium: (
        font-weight: 600
    ),
    title-small: (
        font-weight: 600
    ),
);

@function mat-get-theme-font-typography-property($theme, $typescale, $property) {

    $result: #{mat.get-theme-typography($theme, $typescale, $property)};

    @if $property == font {
        $font-size: mat-get-theme-font-typography-property($theme, $typescale, font-size);
        $line-height: mat-get-theme-font-typography-property($theme, $typescale, line-height);
        $font-weight: mat-get-theme-font-typography-property($theme, $typescale, font-weight);
        $font-family: $global-font-family, sans-serif;
        $result: #{$font-weight} #{$font-size} / #{$line-height} #{$font-family};
    }
    @else if $property == font-family {
        // Cannot use: mat.get-theme-typography($theme, display-medium, font)
        // because it setting the wrong font type.
        $result: $global-font-family;
    }

    $config: map-get($md-typography-config, $typescale);

    @if $config == null {
        $config: ();
    }

    @if map-has-key($config, $property) {
        $result: map-get($config, $property);
    }
    
    @return $result;
}

@mixin apply-theme-typography($theme, $type) {
    // Cannot be used because it setting the wrong font type.
    // font: mat.get-theme-typography($theme, display-medium, font);
    font-family: $global-font-family; // map-get($typography, 'font-family');
    font-size: mat-get-theme-font-typography-property($theme, $type, font-size);
    font-weight: mat-get-theme-font-typography-property($theme, $type, font-weight);
    line-height: mat-get-theme-font-typography-property($theme, $type, line-height);
    letter-spacing: mat-get-theme-font-typography-property($theme, $type, letter-spacing);
}


@debug(mat.$green-palette);

@mixin apply-dynamic-palette-variables-from-palette($prefix, $palette, $tertiaryPalette: null) {
    @each $key, $value in $palette {
        @if meta.type-of($value)=='map' {
            @each $inner-key, $inner-value in $value {
                @if $key == error {
                    --#{$prefix}-#{$key}-#{$inner-key}: #{$inner-value};
                }
                @else {
                    --#{$prefix}-p-#{$key}-#{$inner-key}: #{$inner-value};
                }
            }
        }

        @else {
            --#{$prefix}-primary-#{$key}: #{$value};
        }
    }

    @if($tertiaryPalette != null) {
        @include apply-dynamic-palette-variables-from-palette($prefix + '-tertiary', $tertiaryPalette)
    }
}

@mixin apply-dynamic-palette-variables() {
    --dynamic-palette-primary-0:  #000000;
    --dynamic-palette-primary-10: #001f24;
    --dynamic-palette-primary-20: #00363d;
    --dynamic-palette-primary-25: #00424a;
    --dynamic-palette-primary-30: #004f58;
    --dynamic-palette-primary-35: #005b66;
    --dynamic-palette-primary-40: #006874;
    --dynamic-palette-primary-50: #068391;
    --dynamic-palette-primary-60: #389eac;
    --dynamic-palette-primary-70: #58b9c7;
    --dynamic-palette-primary-80: #75d4e4;
    --dynamic-palette-primary-90: #98f0ff;
    --dynamic-palette-primary-95: #d0f8ff;
    --dynamic-palette-primary-98: #edfcff;
    --dynamic-palette-primary-99: #f6feff;
    --dynamic-palette-primary-100: #ffffff;

    --dynamic-palette-p-secondary-0:  #000000;
    --dynamic-palette-p-secondary-10: #001f24;
    --dynamic-palette-p-secondary-20: #00363d;
    --dynamic-palette-p-secondary-25: #00424a;
    --dynamic-palette-p-secondary-30: #004f58;
    --dynamic-palette-p-secondary-35: #005b66;
    --dynamic-palette-p-secondary-40: #006874;
    --dynamic-palette-p-secondary-50: #068391;
    --dynamic-palette-p-secondary-60: #389eac;
    --dynamic-palette-p-secondary-70: #58b9c7;
    --dynamic-palette-p-secondary-80: #75d4e4;
    --dynamic-palette-p-secondary-90: #98f0ff;
    --dynamic-palette-p-secondary-95: #d0f8ff;
    --dynamic-palette-p-secondary-98: #edfcff;
    --dynamic-palette-p-secondary-99: #f6feff;
    --dynamic-palette-p-secondary-100:#ffffff;

    --dynamic-palette-p-neutral-0: #000000;
    --dynamic-palette-p-neutral-10: #191c1c;
    --dynamic-palette-p-neutral-20: #2e3131;
    --dynamic-palette-p-neutral-25: #393c3c;
    --dynamic-palette-p-neutral-30: #454748;
    --dynamic-palette-p-neutral-35: #505353;
    --dynamic-palette-p-neutral-40: #5c5f5f;
    --dynamic-palette-p-neutral-50: #757778;
    --dynamic-palette-p-neutral-60: #8f9191;
    --dynamic-palette-p-neutral-70: #aaabac;
    --dynamic-palette-p-neutral-80: #c5c7c7;
    --dynamic-palette-p-neutral-90: #e1e3e3;
    --dynamic-palette-p-neutral-95: #f0f1f1;
    --dynamic-palette-p-neutral-98: #f9f9fa;
    --dynamic-palette-p-neutral-99: #fbfcfc;
    --dynamic-palette-p-neutral-100: #ffffff;

    --dynamic-palette-p-neutral-4: #0a0b0a;
    --dynamic-palette-p-neutral-6: #10110e;
    --dynamic-palette-p-neutral-12: #1e201c;
    --dynamic-palette-p-neutral-17: #292b27;
    --dynamic-palette-p-neutral-22: #333531;
    --dynamic-palette-p-neutral-24: #383a36;
    --dynamic-palette-p-neutral-87: #dadbd4;
    --dynamic-palette-p-neutral-92: #e8e9e2;
    --dynamic-palette-p-neutral-94: #eeeee8;
    --dynamic-palette-p-neutral-96: #f4f4ee;

    --dynamic-palette-p-neutral-variant-0: #000000;
    --dynamic-palette-p-neutral-variant-10: #161d1e;
    --dynamic-palette-p-neutral-variant-20: #2b3233;
    --dynamic-palette-p-neutral-variant-25: #363d3e;
    --dynamic-palette-p-neutral-variant-30: #414849;
    --dynamic-palette-p-neutral-variant-35: #4d5455;
    --dynamic-palette-p-neutral-variant-40: #586061;
    --dynamic-palette-p-neutral-variant-50: #71787a;
    --dynamic-palette-p-neutral-variant-60: #8b9293;
    --dynamic-palette-p-neutral-variant-70: #a5acae;
    --dynamic-palette-p-neutral-variant-80: #c1c8c9;
    --dynamic-palette-p-neutral-variant-90: #dde4e5;
    --dynamic-palette-p-neutral-variant-95: #ebf2f3;
    --dynamic-palette-p-neutral-variant-98: #f4fbfc;
    --dynamic-palette-p-neutral-variant-99: #f7fdff;
    --dynamic-palette-p-neutral-variant-100: #ffffff;

    --dynamic-palette-error-0: #000000;
    --dynamic-palette-error-10: #410002;
    --dynamic-palette-error-20: #690005;
    --dynamic-palette-error-25: #7e0007;
    --dynamic-palette-error-30: #93000a;
    --dynamic-palette-error-35: #a80710;
    --dynamic-palette-error-40: #ba1a1a;
    --dynamic-palette-error-50: #de3730;
    --dynamic-palette-error-60: #ff5449;
    --dynamic-palette-error-70: #ff897d;
    --dynamic-palette-error-80: #ffb4ab;
    --dynamic-palette-error-90: #ffdad6;
    --dynamic-palette-error-95: #ffedea;
    --dynamic-palette-error-98: #fff8f7;
    --dynamic-palette-error-99: #fffbff;
    --dynamic-palette-error-100: #ffffff;
}

@function create-palette-from-variables($prefix: dynamic-palette) {
    @return (0: var(--#{$prefix}-primary-0),
            10: var(--#{$prefix}-primary-10),
            20: var(--#{$prefix}-primary-20),
            25: var(--#{$prefix}-primary-25),
            30: var(--#{$prefix}-primary-30),
            35: var(--#{$prefix}-primary-35),
            40: var(--#{$prefix}-primary-40),
            50: var(--#{$prefix}-primary-50),
            60: var(--#{$prefix}-primary-60),
            70: var(--#{$prefix}-primary-70),
            80: var(--#{$prefix}-primary-80),
            90: var(--#{$prefix}-primary-90),
            95: var(--#{$prefix}-primary-95),
            98: var(--#{$prefix}-primary-98),
            99: var(--#{$prefix}-primary-99),
            100: var(--#{$prefix}-primary-100),
            secondary: (
                0: var(--#{$prefix}-p-secondary-0),
                10: var(--#{$prefix}-p-secondary-10),
                20: var(--#{$prefix}-p-secondary-20),
                25: var(--#{$prefix}-p-secondary-25),
                30: var(--#{$prefix}-p-secondary-30),
                35: var(--#{$prefix}-p-secondary-35),
                40: var(--#{$prefix}-p-secondary-40),
                50: var(--#{$prefix}-p-secondary-50),
                60: var(--#{$prefix}-p-secondary-60),
                70: var(--#{$prefix}-p-secondary-70),
                80: var(--#{$prefix}-p-secondary-80),
                90: var(--#{$prefix}-p-secondary-90),
                95: var(--#{$prefix}-p-secondary-95),
                98: var(--#{$prefix}-p-secondary-98),
                99: var(--#{$prefix}-p-secondary-99),
                100: var(--#{$prefix}-p-secondary-100)
            ),
            neutral: (
                0: var(--#{$prefix}-p-neutral-0),
                10: var(--#{$prefix}-p-neutral-10),
                20: var(--#{$prefix}-p-neutral-20),
                25: var(--#{$prefix}-p-neutral-25),
                30: var(--#{$prefix}-p-neutral-30),
                35: var(--#{$prefix}-p-neutral-35),
                40: var(--#{$prefix}-p-neutral-40),
                50: var(--#{$prefix}-p-neutral-50),
                60: var(--#{$prefix}-p-neutral-60),
                70: var(--#{$prefix}-p-neutral-70),
                80: var(--#{$prefix}-p-neutral-80),
                90: var(--#{$prefix}-p-neutral-90),
                95: var(--#{$prefix}-p-neutral-95),
                98: var(--#{$prefix}-p-neutral-98),
                99: var(--#{$prefix}-p-neutral-99),
                100: var(--#{$prefix}-p-neutral-100),
                4: var(--#{$prefix}-p-neutral-4),
                6: var(--#{$prefix}-p-neutral-6),
                12: var(--#{$prefix}-p-neutral-12),
                17: var(--#{$prefix}-p-neutral-17),
                22: var(--#{$prefix}-p-neutral-22),
                24: var(--#{$prefix}-p-neutral-24),
                87: var(--#{$prefix}-p-neutral-87),
                92: var(--#{$prefix}-p-neutral-92),
                94: var(--#{$prefix}-p-neutral-94),
                96: var(--#{$prefix}-p-neutral-96)
            ),
            neutral-variant: (
                0: var(--#{$prefix}-p-neutral-variant-0),
                10: var(--#{$prefix}-p-neutral-variant-10),
                20: var(--#{$prefix}-p-neutral-variant-20),
                25: var(--#{$prefix}-p-neutral-variant-25),
                30: var(--#{$prefix}-p-neutral-variant-30),
                35: var(--#{$prefix}-p-neutral-variant-35),
                40: var(--#{$prefix}-p-neutral-variant-40),
                50: var(--#{$prefix}-p-neutral-variant-50),
                60: var(--#{$prefix}-p-neutral-variant-60),
                70: var(--#{$prefix}-p-neutral-variant-70),
                80: var(--#{$prefix}-p-neutral-variant-80),
                90: var(--#{$prefix}-p-neutral-variant-90),
                95: var(--#{$prefix}-p-neutral-variant-95),
                98: var(--#{$prefix}-p-neutral-variant-98),
                99: var(--#{$prefix}-p-neutral-variant-99),
                100: var(--#{$prefix}-p-neutral-variant-100)
            ),
            error: (
                0: var(--#{$prefix}-error-0),
                10: var(--#{$prefix}-error-10),
                20: var(--#{$prefix}-error-20),
                25: var(--#{$prefix}-error-25),
                30: var(--#{$prefix}-error-30),
                35: var(--#{$prefix}-error-35),
                40: var(--#{$prefix}-error-40),
                50: var(--#{$prefix}-error-50),
                60: var(--#{$prefix}-error-60),
                70: var(--#{$prefix}-error-70),
                80: var(--#{$prefix}-error-80),
                90: var(--#{$prefix}-error-90),
                95: var(--#{$prefix}-error-95),
                98: var(--#{$prefix}-error-98),
                99: var(--#{$prefix}-error-99),
                100: var(--#{$prefix}-error-100))
            );

    @if (str-slice($prefix, -9) != '-tertiary') {
        @return create-palette-from-variables($prefix + '-tertiary');
    }
}

@function create-palette-from-variables-for-tertiary($prefix: dynamic-palette) {
    @return create-palette-from-variables($prefix + '-tertiary');
}

// Create a theme with the specified color type and density.
@function create-theme($type: light, $density: 0) {
    @return mat.define-theme((color: (theme-type: $type,
                    primary: $test-palette, // matx.$m3-green-palette,
                    tertiary: mat.$violet-palette
                    ),
                typography: (brand-family: $global-font-family ),
                density: (scale: $density ),
            ));
}

@function create-dynamic-theme($type: light, $density: 0) {
    $primary-palette: create-palette-from-variables(dynamic-primary-palette);
    $tertiary-palette: create-palette-from-variables-for-tertiary(dynamic-primary-palette);
    @return mat.define-theme((color: (theme-type: $type,
                    primary: $primary-palette,
                    tertiary: $tertiary-palette ),
                typography: (brand-family: $global-font-family ),
                density: (scale: $density ),
            ));
}

// Define the default (light) theme.
$light-theme: create-theme($type: light);

// Create our dark theme.
$dark-theme: create-theme($type: dark);

$light-dynamic-theme: create-dynamic-theme($type: light);
$dark-dynamic-theme: create-dynamic-theme($type: dark);

$theme: $light-theme;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
// @include mat.core();


@mixin apply-m3-theme($theme) {
    --app-text-color: #{mat.get-theme-color($theme, on-primary)};
    --app-background-color: #{mat.get-theme-color($theme, surface-container-lowest)}; //#{mat.get-theme-color($theme, primary)};
    --app-secondary-text-color: #{mat.get-theme-color($theme, on-secondary-container)}; // mat.get-theme-color($theme, foreground, secondary-text);
    --app-secondary-opacity: 0.54;
    --app-divider-color: #{mat.get-theme-color($theme, primary)}; // mat.get-theme-color($theme, foreground, divider)};
    --app-card-background-color: #{mat.get-theme-color($theme, primary-container)}; // map-get($background, card);
    --app-background-image-radient-target: #{mat.get-theme-color($theme, primary, 100)};
    --app-accent-background-color: #{mat.get-theme-color($theme, primary, 50)};
    --app-nav-toolbar-background-color: #{mat.get-theme-color($theme, primary, 70)};
    --app-nav-offsetbar-background-color: #{mat.get-theme-color($theme, secondary, 40)};
    --app-primary-dark-background-color: #{mat.get-theme-color($theme, primary, 90)};
    --app-accent-dark-background-color: #{mat.get-theme-color($theme, secondary, 90)};
    --app-background-900-contrast: #{mat.get-theme-color($theme, tertiary, 90)};
    --app-background-100-contrast: #{mat.get-theme-color($theme, tertiary, 100)};
    --app-error-text-color: #{mat.get-theme-color($theme, on-error-container)};
    --app-error-background-color: #{mat.get-theme-color($theme, error-container)};
    --app-list-item-hover-background-color: #{mat.get-theme-color($theme, primary, 60)};
    --app-list-item-outline-background-color: #{mat.get-theme-color($theme, secondary, 60)};
    --app-tooltip-text-color: #{mat.get-theme-color($theme, secondary, 60)};
    --app-header-background-color: #{mat.get-theme-color($theme, surface)};
    --app-header-color: #{mat.get-theme-color($theme, on-surface)};
    --app-footer-background-color: unset;
    --app-footer-color: #{mat.get-theme-color($theme, on-surface)};

    // Define color role variables.
    @each $color-role in $md-color-roles {
        --app-m3-color-role--#{$color-role}: #{mat.get-theme-color($theme, $color-role)};
    }

    @each $color-palette in $md-color-palettes {
        @each $hue-value in $md-color-hues {
            --app-m3-color-role--#{$color-palette}-#{$hue-value}: #{mat.get-theme-color($theme, $color-palette, $hue-value)};
        }
    }


    // Define typescale classes.
    @each $typescale in $md-typescales {
        .app-md-#{$typescale} {
            @include apply-theme-typography($theme, $typescale);
        }
    }

    @each $typescale in $md-typescales {
        @each $property in $md-typescale-properties {
            --app-m3-typo--#{$typescale}-#{$property}: #{mat-get-theme-font-typography-property($theme, $typescale, $property)};
        }
    }

    mat-drawer-container {
        --mat-sidenav-container-text-color: #{mat.get-theme-color($theme, on-surface)};
        --mat-sidenav-container-background-color: #{mat.get-theme-color($theme, surface-container)};
        --mat-sidenav-content-text-color: #{mat.get-theme-color($theme, on-surface)};
        --mat-sidenav-content-background-color: #{mat.get-theme-color($theme, surface)};
    }

    .mat-mdc-tab {
        @include apply-md-typography(title-small);
    }

    @include apply-md-typography(body-large, $remap: (
        'font-size': '--mdc-filled-button-label-text-size',
        'font-family': '--mdc-filled-button-label-text-font',
        'letter-spacing': '--mdc-filled-button-label-text-tracking',
        'font-weight': '600', // '--mdc-filled-button-label-text-weight',
        'line-height': null
    ));

    @include apply-md-typography(title-large, $remap: (
        'font-size': null,
        'font-family': '--mat-stepper-container-text-font',
        'letter-spacing': null,
        'font-weight': null,
        'line-height': null
    ));

    @include apply-md-typography(body-medium, $remap: (
        'font-size': '--mat-stepper-header-label-text-size',
        'font-family': '--mat-stepper-header-label-text-font',
        'letter-spacing': null,
        'font-weight': '--mat-stepper-header-label-text-weight',
        'line-height': null
    ));

    @include apply-md-typography(body-large, $remap: (
        'font-size': '--mdc-outlined-text-field-label-text-size',
        'font-family': '--mdc-outlined-text-field-label-text-font',
        'letter-spacing': '--mdc-outlined-text-field-label-text-tracking',
        'font-weight': '--mdc-outlined-text-field-label-text-weight',
        'line-height': null
    ));

    @include apply-md-typography(body-large, $remap: (
        'font-size': '--mat-form-field-container-text-size',
        'font-family': '--mat-form-field-container-text-font',
        'letter-spacing': '--mat-form-field-container-text-tracking',
        'font-weight': '--mat-form-field-container-text-weight',
        'line-height': null // '--mat-form-field-container-text-line-height'
    ));

    // The subscript section of a <mat-form-field> that display the field error.
    @include apply-md-typography(label-medium, $remap: (
        'font-size': '--mat-form-field-subscript-text-size',
        'font-family': '--mat-form-field-subscript-text-font',
        'letter-spacing': '--mat-form-field-subscript-text-tracking',
        'font-weight': '--mat-form-field-subscript-text-weight',
        'line-height': null // '--mat-form-field-subscript-text-line-height'
    ));

    @include apply-md-typography(body-large, $remap: (
        'font-size': '--mat-select-trigger-text-size',
        'font-family': '--mat-select-trigger-text-font',
        'letter-spacing': '--mat-select-trigger-text-tracking',
        'font-weight': '--mat-select-trigger-text-weight',
        'line-height': null // '--mat-select-trigger-text-line-height'
    ));

    @include apply-md-typography(body-medium, $remap: (
        'font-size': '--mat-paginator-container-text-size',
        'font-family': '--mat-paginator-container-text-font',
        'letter-spacing': '--mat-paginator-container-text-tracking',
        'font-weight': '--mat-paginator-container-text-weight',
        'line-height': null // '--mat-paginator-container-text-line-height'
    ));

    @include apply-md-typography(label-large, $remap: (
        'font-size': '--mat-standard-button-toggle-label-text-size',
        'font-family': '--mat-standard-button-toggle-label-text-font',
        'letter-spacing': '--mat-standard-button-toggle-label-text-tracking',
        'font-weight': '--mat-standard-button-toggle-label-text-weight',
        'line-height': null // '--mat-standard-button-toggle-label-text-line-height'
    ));

    @include apply-md-typography(label-large, $remap: (
        'font-size': '--mdc-form-field-label-text-size',
        'font-family': '--mdc-form-field-label-text-font',
        'letter-spacing': '--mdc-form-field-label-text-tracking',
        'font-weight': '--mdc-form-field-label-text-weight',
        'line-height': '--mdc-form-field-label-text-line-height'
    ));

    @include apply-md-typography(label-large, $remap: (
        'font-size': '--mat-menu-item-label-text-size',
        'font-family': '--mat-menu-item-label-text-font',
        'letter-spacing': '--mat-menu-item-label-text-tracking',
        'font-weight': '--mat-menu-item-label-text-weight',
        'line-height': '--mat-menu-item-label-text-line-height'
    ));

    @include apply-md-typography(label-large, $remap: (
        'font-size': '--mdc-text-button-label-text-size',
        'font-family': '--mdc-text-button-label-text-font',
        'letter-spacing': '--mdc-text-button-label-text-tracking',
        'font-weight': '--mdc-text-button-label-text-weight',
        'line-height': null
    ));

    @include apply-md-typography(title-medium, $remap: (
        'font-size': '--mat-expansion-header-text-size',
        'font-family': '--mat-expansion-header-text-font',
        'letter-spacing': '--mat-expansion-header-text-tracking',
        'font-weight': '--mat-expansion-header-text-weight',
        'line-height': '--mat-expansion-header-text-line-height'
    ));

    @include apply-md-typography(body-large, $remap: (
        'font-size': '--mat-expansion-container-text-size',
        'font-family': '--mat-expansion-container-text-font',
        'letter-spacing': '--mat-expansion-container-text-tracking',
        'font-weight': '--mat-expansion-container-text-weight',
        'line-height': '--mat-expansion-container-text-line-height'
    ));

    @include apply-md-typography(label-large, $remap: (
        'font-size': '--mdc-outlined-button-label-text-size',
        'font-family': '--mdc-outlined-button-label-text-font',
        'letter-spacing': '--mdc-outlined-button-label-text-tracking',
        'font-weight': '--mdc-outlined-button-label-text-weight',
        'line-height': null
    ));

    @include apply-md-typography(body-large, $remap: (
        'font-size': '--mdc-dialog-supporting-text-size',
        'font-family': '--mdc-dialog-supporting-text-font',
        'letter-spacing': '--mdc-dialog-supporting-text-tracking',
        'font-weight': '--mdc-dialog-supporting-text-weight',
        'line-height': '--mdc-dialog-supporting-text-line-height'
    ));
}

.m3-plain-tooltip {
    @include apply-m3-component-styles(plain-tooltip);
}

.m3-rich-tooltip {
    @include apply-m3-component-styles(rich-tooltip);
}


