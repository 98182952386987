/////////////////////
// Classes to be used with the List component:
// https://material.angular.io/components/list/overview
/////////////////////

.horizontal-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap; // Necessary to keep the list responsive in mobile.
    gap: var(--app-container-gap);

    mat-list-item {
        width: initial;
        flex-grow: 0;
        flex-shrink: 1;
    }
}

.small-list {
    // mdc-list class is setting padding 8px 0
    // So remove the padding since the parent should handle the spacing.
    --app-mdc-list-padding: 0px;

    --app-mdc-list-list-item-padding-left: 0px;
    --app-mdc-list-list-item-padding-right: 0px;

    --mdc-list-list-item-label-text-size: 0.80rem;
    --mdc-list-list-item-label-text-line-height: 0.80rem;
    --mdc-list-list-item-one-line-container-height: calc(0.80rem + 15px);

    mat-icon {
        --app-mdc-material-icon-size: 0.80rem;
        margin-right: 5px;
    }

    &.horizontal {
        @extend .horizontal-list;
    }
}

.medium-list {
    // mdc-list class is setting padding 8px 0
    // So remove the padding since the parent should handle the spacing.
    --app-mdc-list-padding: 0px;

    --app-mdc-list-list-item-padding-left: 0px;
    --app-mdc-list-list-item-padding-right: 0px;

    --mdc-list-list-item-label-text-size: 1rem;
    --mdc-list-list-item-label-text-line-height: 1rem;
    --mdc-list-list-item-one-line-container-height: calc(1rem + 15px);

    mat-icon {
        --app-mdc-material-icon-size: 1rem;
        margin-right: 5px;
    }

    &.horizontal {
        @extend .horizontal-list;
    }
}
