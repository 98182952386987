@import "mat-m3-shared";


/////////////////////
// Classes to be used with the Popover directive:
// https://ng-bootstrap.github.io/#/components/popover/api
/////////////////////

.app-popover--container {
    max-width: 400px;
    /* optional max width */
    width: intrinsic;
    /* Safari/WebKit uses a non-standard name */
    width: -moz-max-content;
    /* Firefox/Gecko */
    width: -webkit-max-content;
    /* Chrome */

    --bs-body-bg: royalblue !important;
}

.app-popover--plain-tooltip {
    $remap: (
        'color': '--bs-popover-body-color',
        'background-color': ('--bs-popover-bg', '--bs-popover-arrow-border')
    );

    @include apply-m3-component-styles(plain-tooltip, $remap);

    --bs-popover-body-padding-x: 8px;
    --bs-popover-body-padding-y: 5px;
}

.app-popover--rich-tooltip {
    $remap: (
        'color': '--bs-popover-body-color',
        'background-color': ('--bs-popover-bg', '--bs-popover-arrow-border')
    );

    @include apply-m3-component-styles(rich-tooltip, $remap);

    --bs-popover-body-padding-x: 8px;
    --bs-popover-body-padding-y: 5px;
}