/////////////////////
// Classes to be used with the button toggle:
// https://material.angular.io/components/button-toggle
//
// The button toggle correspond to the segmented buttons of the material design m3:
// https://m3.material.io/components/segmented-buttons/overview
/////////////////////

.app-button-toggle--full-width {
    mat-button-toggle {
        width: 100%;
    }
}