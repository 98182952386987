@use '@angular/material' as mat;

@mixin app-text-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .secondary-text {
        color: var(--app-secondary-text-color);
        opacity: var(--app-secondary-opacity);
    }

    .activated-text {
        color: #33AA33;
    }

    .deactivated-text {
        color: var(--app-secondary-text-color);
    }

    .subscription-no {
        color: var(--app-secondary-text-color);
        font-size: 0.6em;
    }

    .text-white {
        color: white;
    }
    
    .text-black {
        color: black;
    }
}

@mixin apply-text-overflow-ellipsis($display: block, $nbLines: null) {
    // Won't work with display: flex
    $supported-display: (
        block,
        inline-block
    );

    @if not index($supported-display, $display) {
        @error "The display '#{$display}' is not valid. Please choose one of the following values : #{$supported-display}.";
    }

    @if $nbLines != null and $nbLines > 1 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $nbLines; // Number of lines before displaying ellipsis
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
    }
    @else {
        display: $display;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@mixin apply-secondary-text() {
    color: var(--app-secondary-text-color);
    opacity: var(--app-secondary-opacity);
}