// Class that maybe needed for the intermediate parent to have a verticale scrollable area.
%scrollable-vertical-parent {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    min-height: 0px;
}

@mixin apply-vertical-align($position: center, $flex-direction: 'column') {
    @if $flex-direction =='column' or $flex-direction =='column-reverse' {
        @if $position ==center {
            display: flex;
            flex-direction: #{$flex-direction};
            justify-content: center;
            height: 100%;
            align-items: flex-start; // Prevents horizontal stretching
        }

        @else if $position =='top' {
            display: flex;
            flex-direction: #{$flex-direction};
            justify-content: flex-start;
            height: 100%;
            align-items: flex-start; // Prevents horizontal stretching  
        }

        @else if $position =='bottom' {
            display: flex;
            flex-direction: #{$flex-direction};
            justify-content: flex-end;
            height: 100%;
            align-items: flex-start; // Prevents horizontal stretching  
        }

        @else {
            @warn "The position '#{$position}' is not valid. Please choose one : center, bottom or top.";
        }
    }

    @else {
        @if $position ==center {
            display: flex;
            flex-direction: #{$flex-direction};
            align-items: center;
            height: 100%;
            justify-content: flex-start; // Prevents horizontal stretching
        }

        @else if $position =='top' {
            display: flex;
            flex-direction: #{$flex-direction};
            align-items: flex-start;
            height: 100%;
            justify-content: flex-start; // Prevents horizontal stretching  
        }

        @else if $position =='bottom' {
            display: flex;
            flex-direction: #{$flex-direction};
            align-items: flex-end;
            height: 100%;
            justify-content: flex-start; // Prevents horizontal stretching  
        }

        @else {
            @warn "The position '#{$position}' is not valid. Please choose one : center, bottom or top.";
        }
    }


}

@mixin apply-alignment($horizontal: null, $vertical: null, $flex-direction: 'column') {
    display: flex;
    flex-direction: #{$flex-direction};

    @if $flex-direction =='column' or $flex-direction =='column-reverse' {

        @if $horizontal ==center {
            align-items: center;
            width: 100%;
        }

        @else if $horizontal ==left {
            align-items: flex-start;
        }

        @else if $horizontal ==right {
            align-items: flex-end;
            width: 100%;
        }

        @else if($horizontal ==null) {}

        @else {
            @warn "The horizontal '#{$horizontal}' is not valid. Please choose one : center, left or right";
        }

        @if $vertical ==center {
            justify-content: center;
            height: 100%;
        }

        @else if $vertical ==top {
            justify-content: flex-start;
        }

        @else if $vertical ==bottom {
            justify-content: flex-end;
            height: 100%;
        }

        @else if($vertical ==null) {}

        @else {
            @warn "The vertical '#{$vertical}' is not valid. Please choose one : center, bottom or top.";
        }
    }

    @else {
        @if $horizontal ==center {
            justify-content: center;
            width: 100%;
        }

        @else if $horizontal ==left {
            justify-content: flex-start;
        }

        @else if $horizontal ==right {
            justify-content: flex-end;
            width: 100%;
        }

        @else if($horizontal ==null) {}

        @else {
            @warn "The horizontal '#{$horizontal}' is not valid. Please choose one : center, left or right";
        }

        @if $vertical ==center {
            align-items: center;
            height: 100%;
        }

        @else if $vertical ==top {
            align-items: flex-start;
        }

        @else if $vertical ==bottom {
            align-items: flex-end;
            height: 100%;
        }

        @else if($vertical ==null) {}

        @else {
            @warn "The vertical '#{$vertical}' is not valid. Please choose one : center, bottom or top.";
        }
    }


}

@mixin apply-fixed-size($width: null, $height: null) {

    @if $width !=null {
        width: $width;
        min-width: $width;
        max-width: $width;
    }

    @if $height !=null {
        height: $height;
        min-height: $height;
        max-height: $height;
    }
}

@mixin apply-fixed-size($width: null, $height: null) {

    @if $width !=null {
        width: $width;
        min-width: $width;
        max-width: $width;
    }

    @if $height !=null {
        height: $height;
        min-height: $height;
        max-height: $height;
    }
}

// Remove default element margins.
@mixin remove-default-margins() {
    
    hr, mat-divider, h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 0;
    }
}


@mixin flex-setup($type, $gap: null) {
    display: flex;
    flex-direction: $type;

    @if $gap == null {
        gap: var(--app-container-gap);
    }

    @if $type == 'column' {
        @if $gap != 0 {
            @include remove-default-margins();
        }
    }
}