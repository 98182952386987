.app-dialog--panel-class--form {
    --mat-dialog-container-max-width: calc(var(--app-form-container--max-width) + 2 * var(--app-container-padding));

    // We want the content to take the --mat-dialog-container-max-width width if there is sufficent space.
    width: 100%;
}

.app-dialog--panel-class--full-width {
    --mat-dialog-container-max-width: 100%;
    width: 100%;
}

.app-dialog--panel-class--with-border {
    .mat-mdc-dialog-surface {
        border-width: 1px;
        border-color: black;
        border-style: solid;
        box-sizing: border-box;
    }
}

.app-dialog-host {
    @extend .flex-column;
    @extend .flex-default-gap;

    --mat-dialog-with-actions-content-padding: 0px;
    
    .app-dialog-content-container {
        display: flex;
        flex-direction: column;
        gap: var(--app-container-gap);
        padding: 0px;
    }
    
    .app-dialog-content {
        display: flex;
        flex-direction: column;
        gap: var(--app-container-gap);
        padding: 0px;
    }
}

.app-dialog--full-content {
    --app-mdc-dialog-content-max-height: 90%;
    --app-mdc-dialog-content-flex-grow: 1;
}

.app-dialog-header {
    color: var(--mdc-dialog-supporting-text-color, rgba(0, 0, 0, 0.6));
}

.app-dialog-step {
    display: flex;
    flex-flow: column;
    gap: var(--app-container-gap);
    margin-top: 5px;
}