@import "mat-m3-shared";

// Apply Angular Material added variables (because initially some properties doesn't use variables).
// Those correspond to the default value. It may change between updates so it must be verified manually.
@mixin apply-added-angular-material-variables() {
    --app-mdc-material-icon-size: 24px;
    --app-mat-ripple-element--display: inherit;
    --app-mat-mdc-form-field-subscript-wrapper--height: inherit;
    --app-mat-horizontal-content-container--padding: 0 24px 24px 24px;
    --app-mat-horizontal-stepper-header--padding: 0 24px;
    --app-mat-mdc-form-field--date-range-input--width: 225px; // Default is 200px;
    --app-mat-mdc-form-field-infix--width: 180px;
    --app-mdc-card-content--color: inherit;
    --app-mdc-card-content--padding-left: 16px;
    --app-mdc-card-content--padding-right: 16px;
    --app-mdc-card-content--padding-top: 16px;
    --app-mdc-card-content--padding-bottom: 16px;
    --app-mdc-data-table--min-width: 100%;
    --app-mdc-data-table--padding-left: 15px;
    --app-mdc-data-table--padding-right: 15px;
    --app-mdc-data-table--padding-top: 15px;
    --app-mdc-data-table--padding-bottom: 15px;
    --app-mdc-data-table__row--bottom-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
    --app-mdc-data-table__row--bottom-width: 0px;
    --app-mdc-data-table__row--bottom-style: solid;
    --app-mdc-data-table__cell--padding-left: 16px;
    --app-mdc-data-table__cell--padding-right: 16px;
    --app-mdc-data-table__cell--padding-top: 0px;
    --app-mdc-data-table__cell--padding-bottom: 0px;
    --app-mdc-data-table__cell--margin-left: 16px; // This is used for the space between cells.
    --app-mdc-data-table__cell--first-element-margin-left: 24px;
    --app-mdc-dialog-content-max-height: 65vh;
    --app-mdc-dialog-content-flex-grow: 0;
    --app-mdc-dialog-content-flex-shrink: 0;
    --app-mdc-list-padding: 8px 0;
    --app-mdc-list-list-item-padding-left: 16px;
    --app-mdc-list-list-item-padding-right: 16px;
    --app-mdc-text-field--outlined--height: 56px;
    --app-mat-mdc-text-field-wrapper--height: auto;
    --app-mat-mdc-dialog-container--max-height: 100vh; // Default is 100% so it may be not accessible if page is more dans 100% navigator height (and the vertical scrollbar of the page won't be accessible behind the dialog).
}

@mixin app-angular-material-override($theme) {

    // All the following to simply have a smaller inputs...
    // To put the the parent div element of a mat-form-field.
    .compact-input {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-appearance-outline .mat-form-field-wrapper {
            margin: unset !important;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-wrapper {
            padding-bottom: unset !important;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-prefix,
        .mat-form-field-suffix {
            top: unset !important;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-appearance-outline .mat-form-field-infix {
            padding: unset !important;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label-wrapper {
            top: -21px !important;
            font-size: 14px;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
        .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
            top: 32px !important;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
            height: 25px !important;
            border-top-width: 15px;
        }
    }

    .no-border-form-field {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-outline {
            color: white !important;
        }

        // Since the outline is not displayed, must remove the left padding to make it aligned with others mat-form-field fields.
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-flex {
            padding-left: 0px !important;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
            border-top: unset !important;
        }
    }

    .checkbox-form-field {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-wrapper {
            padding-bottom: 0px;
        }
    }

    // Without space reserved below for the error message.
    .checkbox-no-error-form-field {

        margin-bottom: 0px !important;

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-wrapper {
            padding-bottom: 0px;
        }
    }


    // Remove some spacing in narrow screens.
    @media (max-width: 400px) {

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label {
            padding: 0 17px !important;
        }
    }

    .no-subscripting-form-field {
        .mat-mdc-form-field-subscript-wrapper {
            height: 0px;
        }
    }

    // Because new mdc checkbox are 48x48.
    .mat-internal-form-field {
        // Don't want scrollbar to appear because of an overflow from inside the component.
        overflow: hidden;
    }
    .small-checkbox {
        .mat-internal-form-field {
            height: 24px;
        }

        .mdc-checkbox {
            height: 24px;
        }

        .mdc-label {
            height: 24px;
        }
    }

    // We don't want the scrollbar appear just because the inside element with class "mat-mdc-button-touch-target" that increase the size of the clicking area. It happened in a flex with the mat-icon-button was the last element.
    button {
        overflow: hidden !important;
    }

    mat-dialog-content {
        // To be able to shrink the dialog if necessary (ex. if max-height is set to 100%).
        flex-grow: var(--app-mdc-dialog-content-flex-grow);
        flex-shrink: var(--app-mdc-dialog-content-flex-shrink);

        // Add this to be able to change the max-height using the --app-mdc-dialog-max-height variable.
        max-height: var(--app-mdc-dialog-content-max-height) !important;
    }

    .mdc-list {
        padding: var(--app-mdc-list-padding);
    }

    .mdc-list-item {
        padding-left: var(--app-mdc-list-list-item-padding-left);
        padding-right: var(--app-mdc-list-list-item-padding-right);
    }

    .mat-icon {
        height: var(--app-mdc-material-icon-size);
        width: var(--app-mdc-material-icon-size);
    }

    .material-icons {
        font-size: var(--app-mdc-material-icon-size);
    }

    .mat-mdc-card-content {
        padding: unset;
        color: var(--app-mdc-card-content--color);
        padding-left: var(--app-mdc-card-content--padding-left);
        padding-right: var(--app-mdc-card-content--padding-right);
        padding-top: var(--app-mdc-card-content--padding-top);
        padding-bottom: var(--app-mdc-card-content--padding-bottom);
    }

    .mat-mdc-card-content:last-child {
        padding-bottom: var(--app-mdc-card-content--padding-bottom);
    }

    .mat-mdc-card-content:first-child {
        padding-top: var(--app-mdc-card-content--padding-top);
    }

    .mat-ripple-element {
        display: var(--app-mat-ripple-element--display);
    }

    .mdc-data-table__cell:first-of-type,
    .mdc-data-table__header-cell:first-of-type {
        margin-left: var(--app-mdc-data-table__cell--first-element-margin-left);

        // Override default padding left since it should be a margin here!
        padding-left: var(--app-mdc-data-table__cell--padding-left);
    }

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
        margin-left: var(--app-mdc-data-table__cell--margin-left);

        padding-left: var(--app-mdc-data-table__cell--padding-left);
        padding-right: var(--app-mdc-data-table__cell--padding-right);
        padding-top: var(--app-mdc-data-table__cell--padding-top);
        padding-bottom: var(--app-mdc-data-table__cell--padding-bottom);
        padding-bottom: var(--app-mdc-data-table__cell--padding-bottom);
    }

    .mdc-data-table__table {
        // In the mdc, the min-width default value is 100%.
        // But will always expand if there is at least on column without a fixed still (that take all the remaining spaces).
        // Unsetting this property allow to add a padding directly with the mat-table element.
        min-width: var(--app-mdc-data-table--min-width);

        padding-left: var(--app-mdc-data-table--padding-left);
        padding-right: var(--app-mdc-data-table--padding-right);
        padding-top: var(--app-mdc-data-table--padding-top);
        padding-bottom: var(--app-mdc-data-table--padding-bottom);
    }

    .mat-mdc-row {
        border-bottom-color: var(--app-mdc-data-table__row--bottom-color);
        border-bottom-width: var(--app-mdc-data-table__row--bottom-width);
        border-bottom-style: var(--app-mdc-data-table__row--bottom-style);
    }

    // Sometimes, the right border dissapear, so adding this resolve the problem.
    // To reproduce the problem:
    //    Disable this style class
    //    Go to the account page.
    //    Resize the page width and see the zipcode field right border dissapear.
    .mdc-notched-outline__trailing {
        margin-right: 1px;
    }

    .mdc-text-field--outlined {
        height: var(--app-mdc-text-field--outlined--height);
    }

    .mat-mdc-text-field-wrapper {
        height: var(--app-mat-mdc-text-field-wrapper--height);
    }

    .mat-mdc-form-field-subscript-wrapper {
        height: var(--app-mat-mdc-form-field-subscript-wrapper--height);
    }

    .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
        width: var(--app-mat-mdc-form-field--date-range-input--width);
    }

    .mat-mdc-form-field-infix {
        width: var(--app-mat-mdc-form-field-infix--width);
    }

    // Because those colors are not initialized with a different color and we can't see the current step.
    .mat-primary {
        --mat-stepper-header-selected-state-icon-background-color: #{get-md-color-role(tertiary)};
        --mat-stepper-header-selected-state-icon-foreground-color: #{get-md-color-role(on-tertiary)};
    }

    .mat-horizontal-content-container {
        padding: var(--app-mat-horizontal-content-container--padding);
    }

    .mat-horizontal-stepper-header {
        padding: var(--app-mat-horizontal-stepper-header--padding);
    }

    // Without the disabled effect.
    .mat-accent.mat-mdc-slide-toggle.normal-slide-toggle {
        $selected-track-color: #e0e0e0;
        $unselected-track-color: #e0e0e0;

        --mdc-switch-unselected-icon-color: white;
        --mdc-switch-selected-icon-color: white;

        --mdc-switch-unselected-pressed-state-layer-color: white;
        --mdc-switch-selected-pressed-state-layer-color: white;

        // Handle Color - Selected
        --mdc-switch-selected-focus-handle-color: white;
        --mdc-switch-selected-handle-color: white;
        --mdc-switch-selected-hover-handle-color: white;
        --mdc-switch-selected-pressed-handle-color: white;

        // Handle Color - Unselected
        --mdc-switch-unselected-focus-handle-color: white;
        --mdc-switch-unselected-handle-color: white;
        --mdc-switch-unselected-hover-handle-color: white;
        --mdc-switch-unselected-pressed-handle-color: white;

        // Track Color - Selected
        --mdc-switch-selected-track-color: #{$selected-track-color};
        --mdc-switch-selected-focus-track-color: #{$selected-track-color};
        --mdc-switch-selected-hover-track-color: #{$selected-track-color};
        --mdc-switch-selected-pressed-track-color: #{$selected-track-color};

        // Track Color - Unselected
        --mdc-switch-unselected-track-color: #{$unselected-track-color};
        --mdc-switch-unselected-focus-track-color: #{$unselected-track-color};
        --mdc-switch-unselected-hover-track-color: #{$unselected-track-color};
        --mdc-switch-unselected-pressed-track-color: #{$unselected-track-color};
    }

    // Class to put on the Angular Materials components to make them more responsive and compatible with narrow screens.
    .responsive {
        .mat-mdc-tab-labels {
            flex-wrap: wrap;
        }
    }

}