@import "common/breakpoints";

$global-font-family: 'Plus Jakarta Sans';

$app-form-container--max-width: 735px;
$app-page-content--max-width: 1260px;
$app-page-header-card--max-width: 1260px;
$app-container-gap: 15px;
// For containers that needs to display a border only.
$app-container-border-color: #c4c6d0;

@mixin app-settings-theme($theme) {

    $theme-type: mat.get-theme-type($theme);
    @debug("Apply Settings for theme Type: " + $theme-type);

    --sidebar-width: 280px;
    --sidebar-bottom-section: 200px;
    --page-header-height: 64px;

    // The language button is var(--mdc-icon-button-state-layer-size) height + 2x padding.
    // +2 is when there is not enough space and the text wrap, an extra 2 pixels is needed.
    --page-footer-height: calc(var(--mdc-icon-button-state-layer-size) + 2 * var(--app-page-content--padding) + 2);


    --app-page-content--padding: 15px;
    --app-page-content--display: flex;
    --app-page-content--flex-direction: column;
    --app-page-content--gap: 15px;

    @include breakpoint-lt("sm") {
        --app-page-content--padding: 5px;
        --app-page-content--gap: 5px;
    }

    --app-shape--background-color: rgb(255, 152, 0);

    --app-container-padding: 15px;
    --app-container-gap: #{$app-container-gap};
    --app-container-border-color: #{$app-container-border-color};

    @include breakpoint-lt("sm") {
        --app-container-padding: 5px;
        --app-container-gap: 5px;
    }

    --app-contextual-menu-padding: 8px;

    @include breakpoint-lt("sm") {
        --app-contextual-menu-padding: 4px;
    }

    --app-design-card--color: var(--app-m3-color-role--on-surface);
    --app-design-card--background-color: var(--app-m3-color-role--surface-container);
    
    --app-design-card--grid-template-areas: 'title title'
    'subtitle subtitle'
    'content content';

    --app-design-card--grid-template-columns: auto;
    --app-design-card--grid-template-rows: auto;
    --app-design-card--default-padding: 16px;

    --app-design-card--padding-left: var(--app-design-card--default-padding);
    --app-design-card--padding-right: var(--app-design-card--default-padding);
    --app-design-card--padding-top: var(--app-design-card--default-padding);
    --app-design-card--padding-bottom: var(--app-design-card--default-padding);

    --app-design-card--column-gap: 15px;
    --app-design-card--row-gap: 15px;

    @include breakpoint-lt("sm") {
        --app-design-card--default-padding: 8px;

        --app-design-card--column-gap: 8px;
        --app-design-card--row-gap: 8px;

        --app-design-card--padding-left: var(--app-design-card--default-padding);
        --app-design-card--padding-right: var(--app-design-card--default-padding);
        --app-design-card--padding-top: var(--app-design-card--default-padding);
        --app-design-card--padding-bottom: 50px; // var(--app-design-card--default-padding);
    }

    // Since the background not changing, the inverse color must be used in dark mode.
    @if $theme-type == 'light' {
        --app-page-header-card--color: var(--app-design-card--color);
    }
    @else {
        --app-page-header-card--color: var(--app-m3-color-role--surface);
        --app-page-header-card--subtitle-text-color: rgba(0, 0, 0, 0.54);
    }
    
    
    --app-page-header-card--padding-left: 26px;
    --app-page-header-card--padding-right: 26px;
    --app-page-header-card--padding-top: 26px;
    --app-page-header-card--padding-bottom: 26px;

    @include breakpoint-lt("sm") {
        --app-page-header-card--padding-left: 15px;
        --app-page-header-card--padding-right: 15px;
        --app-page-header-card--padding-top: 15px;
        --app-page-header-card--padding-bottom: 15px;
    }

    --app-page-header-card--column-gap: 5px;
    --app-page-header-card--row-gap: 5px;

    --app-page-header-card--max-width: #{$app-page-header-card--max-width};

    --app-form-container--max-width: #{$app-form-container--max-width};

    --app-page-content--max-width: #{$app-page-content--max-width};

    --app-page-header--title-height: 48px;

    // Angular Material
    --mat-sidenav-container-width: var(--sidebar-width);
    --app-mat-horizontal-content-container--padding: 0px; // Remove unnecessary padding.
    --app-mat-horizontal-stepper-header--padding: 0px; // Remove unnecessary padding.
    // --mat-stepper-header-height: auto;

    // Remove the default padding of the mat-table.
    --app-mdc-data-table--padding-top: 0px;
    --app-mdc-data-table--padding-bottom: 0px;


    --app-common-list--item-hover--background-color: #{get-md-color-palette(tertiary, 95)};
    --app-common-list--header-row--color: #{get-md-color-role(on-tertiary-container)};
    --app-common-list--header-row--background-color: #{get-md-color-role(tertiary-container)};
}