.empty-list-message {
    font-style: italic;
    color: var(--app-secondary-text-color);
    opacity: var(--app-secondary-opacity);
    text-align: center;
    padding: 29px;
}

.no-result-message {
    @extend .empty-list-message;
}

.app-title {
    @include apply-md-typography(title-large);
}

.app-subtitle {
    @include apply-md-typography(title-small);
}