@import "./form";

.app-form-container {
    max-width: var(--app-form-container--max-width);
}

// Add mat-stepper that take the full width but with the width of the steps restricted.
.app-form-step-container {
    display: flex;
    justify-content: center;

    width: 100%;

    mat-stepper {
        width: 100%;
    }

    .mat-horizontal-stepper-header-container {
        padding-bottom: var(--app-container-gap);
    }
    
    .mat-horizontal-stepper-content {
        @extend .app-form-container;
        width: 100%;
        margin: 0px auto;

        // Default stepper flow.
        display: flex;
        flex-direction: column;
        gap: var(--app-container-gap);
    }
}

.app-form-actions {
    @extend .full-width-distributed-row;

    // In case used outside an .app-form-container.
    max-width: var(--app-form-container--max-width);
    
    // --mdc-filled-button-container-shape: 4px;

    // Full width buttons distribution doesn't need the spacer.
    .spacer {
        display: none;
    }
}