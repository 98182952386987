@import "./settings";

// $style-map: map of properties and values to be applied
// $remap : optional map for remapping property names
//          To remap to multiple properties, just use a list.
//          Set null to to skip to ignore a mapping property.
@mixin apply-property-from-map($style-map, $remap: null) {
    @each $key, $value in $style-map {
        // Checks whether to remap the property
        @if $remap != null and map-has-key($remap, $key) {
            $remap-value: map-get($remap, $key);
            
            // Checks if remap value is a list to apply it to multiple properties
            @if type-of($remap-value) == 'list' {
                @each $prop in $remap-value {
                    #{$prop}: #{$value};
                }
            } @else {
                @if($remap-value != null) {
                    #{$remap-value}: #{$value};
                }
            }
        } @else {
            // Applies directly the property if no remap is specified
            #{$key}: #{$value};
        }
    }
}

@function merge-maps($map1, $map2) {
    $merged-map: $map1;

    @each $key, $value in $map2 {
        $merged-map: map-merge($merged-map, ($key: $value));
    }

    @return $merged-map;
}

$md-typescales: (
    display-large,
    display-medium,
    display-small,
    headline-large,
    headline-medium,
    headline-small,
    title-large,
    title-medium,
    title-small,
    body-large,
    body-medium,
    body-small,
    label-large,
    label-medium,
    label-small
);

$md-typescale-properties: (
    font, 
    font-family, 
    font-size, 
    font-weight, 
    line-height, 
    letter-spacing
);




$md-color-palettes: (
    primary,
    secondary,
    tertiary,
    error,
    neutral,
    neutral-variant
);

$md-color-hues: (
    0,
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    95,
    99,
    100
);

// 45 color roles
$md-color-roles: (
    primary,
    on-primary,
    primary-container,
    on-primary-container,
    primary-fixed,
    primary-fixed-dim,
    on-primary-fixed,
    on-primary-fixed-variant,
    secondary,
    on-secondary,
    secondary-container,
    on-secondary-container,
    secondary-fixed,
    secondary-fixed-dim,
    on-secondary-fixed,
    on-secondary-fixed-variant,
    tertiary,
    on-tertiary,
    tertiary-container,
    on-tertiary-container,
    tertiary-fixed,
    tertiary-fixed-dim,
    on-tertiary-fixed,
    on-tertiary-fixed-variant,
    error,
    on-error,
    error-container,
    on-error-container,
    surface-dim,
    surface,
    surface-bright,
    surface-container-lowest,
    surface-container-low,
    surface-container,
    surface-container-high,
    surface-container-highest,
    on-surface,
    on-surface-variant,
    outline,
    outline-variant,
    inverse-surface,
    inverse-on-surface,
    inverse-primary,
    scrim,
    shadow
);


@function get-md-typography-map($typescale, $font-size: null, $font-weight: null) {
    $typo-map: ();

    @if index($md-typescales, $typescale) {
        $typo-map: map-merge($typo-map, ('font-family': $global-font-family));
        


        
        @if $font-size != null {
            $typo-map: map-merge($typo-map, ('font-size': $font-size));
            $typo-map: map-merge($typo-map, ('--app-m3-typo--current-font-size': $font-size));
        }
        @else {
            $typo-map: map-merge($typo-map, ('font-size': var(--app-m3-typo--#{$typescale}-font-size)));
            $typo-map: map-merge($typo-map, ('--app-m3-typo--current-font-size': var(--app-m3-typo--#{$typescale}-font-size)));
        }

        @if $font-weight != null {
            $typo-map: map-merge($typo-map, ('font-weight': $font-weight));
        }
        @else {
            $typo-map: map-merge($typo-map, ('font-weight': var(--app-m3-typo--#{$typescale}-font-weight)));
        }

        
        $typo-map: map-merge($typo-map, ('line-height': var(--app-m3-typo--#{$typescale}-line-height)));
        $typo-map: map-merge($typo-map, ('letter-spacing': var(--app-m3-typo--#{$typescale}-letter-spacing)));
    }

    @else {
        @error "The typescale '#{$typescale}' is not valid. Please choose one of the following values : #{$md-typescales}.";
    }

    @return $typo-map;
}

@mixin apply-md-typography($typescale, $font-size: null, $font-weight: null, $remap: null) {
    @if $remap != null and map-has-key($remap, 'typescale') {
        $typescale: map-get($remap, 'typescale');
    }
    $color-map: get-md-typography-map($typescale, $font-size, $font-weight);
    @include apply-property-from-map($color-map, $remap);
}

@function get-md-color-role($role: null) {
    @if $role !=null {
        @if index($md-color-roles, $role) {
            @return var(--app-m3-color-role--#{$role});
        }
        @else {
            @error "The color role '#{$role}' is not valid. Please choose one of the following values: #{$md-color-roles}.";
        }
    }

    @return null;
}


@function get-md-color-palette($palette, $hue) {
    @if $palette !=null {
        
        @if index($md-color-palettes, $palette) {
            @if index($md-color-hues, $hue) {
                @return var(--app-m3-color-role--#{$palette}-#{$hue});
            }
            @else {
                @error "The hue '#{$hue}' is not valid. Please choose one of the following values: #{$md-color-hues}.";
            }
        }
        @else {
            @error "The color palette '#{$palette}' is not valid. Please choose one of the following values: #{$md-color-palette}.";
        }
    }

    @return null;
}

@function get-md-color-roles-map($color: null, $bgcolor: null, $bordercolor: null) {
    $color-map: ();

    @if $color != null {
        @if index($md-color-roles, $color) {
            $color-map: map-merge($color-map, ('color': var(--app-m3-color-role--#{$color})));
        } @else {
            @error "The color role '#{$color}' is not valid. Please choose one of the following values : #{$md-color-roles}.";
        }
    }

    @if $bgcolor != null {
        @if index($md-color-roles, $bgcolor) {
            $color-map: map-merge($color-map, ('background-color': var(--app-m3-color-role--#{$bgcolor})));
        } @else {
            @error "The background color role '#{$bgcolor}' is not valid. Please choose one of the following values : #{$md-color-roles}.";
        }
    }

    @if $bordercolor != null {
        @if index($md-color-roles, $bordercolor) {
            $color-map: map-merge($color-map, ('border-color': var(--app-m3-color-role--#{$bordercolor})));
        } @else {
            @error "The border color role '#{$bordercolor}' is not valid. Please choose one of the following values : #{$md-color-roles}.";
        }
    }

    @return $color-map;

}

@mixin apply-md-color-roles($color: null, $bgcolor: null, $bordercolor: null, $remap: null) {
    $color-map: get-md-color-roles-map($color, $bgcolor, $bordercolor);
    @include apply-property-from-map($color-map, $remap);
}

@mixin apply-m3-component-styles($componentType, $remap: null) {

    $styles-map: ();

    // See tooltip specs:
    //   https://m3.material.io/components/tooltips/specs
    @if ($componentType == plain-tooltip) {
        @include apply-md-typography(body-small, $remap: $remap);
        @include apply-md-color-roles(inverse-on-surface, inverse-surface,$remap: $remap);
    } @else if ($componentType == rich-tooltip){
        @include apply-md-typography(body-medium, $remap: $remap);
        @include apply-md-color-roles(on-surface-variant, surface-container, $remap: $remap);
    }
    //   https://m3.material.io/components/divider/specs
    @else if ($componentType == divider){
        @include apply-md-color-roles(outline-variant, null, $remap: $remap);
        $styles-map: map-merge($styles-map, ('border-top-style': solid));
        $styles-map: map-merge($styles-map, ('border-top-color': get-md-color-role(outline-variant)));
        $styles-map: map-merge($styles-map, ('border-top-width': var(--mat-divider-width))); // default 1px
        $styles-map: map-merge($styles-map, ('width': 100%));
        $styles-map: map-merge($styles-map, (margin-bottom: var(--mat-menu-divider-bottom-spacing),
                        margin-top: var(--mat-menu-divider-top-spacing))); // default 8px so use those variables from Angular Material.
    }
    @else if ($componentType == segmented-buttons){
        @include apply-md-typography(label-large, $remap: $remap);
    }
    @else {
        @error "Cannot apply m3 component styles for " + $componentType;
    }

    @include apply-property-from-map($styles-map, $remap);
}

@mixin apply-m3-angular-component-styles($componentType, $remap: null) {
    $styles-map: ();

    @if ($componentType == button-toggle) {
        $button-toggle-map: (
            'font-size': '--mat-standard-button-toggle-label-text-size',
            'font-family': '--mat-standard-button-toggle-label-text-font',
            'letter-spacing': '--mat-standard-button-toggle-label-text-tracking',
            'font-weight': '--mat-standard-button-toggle-label-text-weight',
            'line-height': '--mat-standard-button-toggle-label-text-line-height'
        );

        @if($remap != null) {
            $remap: merge-maps($button-toggle-map, $remap);
        }
        @else {
            $remap: $button-toggle-map;
        }

        @include apply-m3-component-styles(segmented-buttons, $remap);
    }
    @else {
        @error "Cannot apply m3 angular component styles for " + $componentType;
    }

    @include apply-property-from-map($styles-map, $remap);
}