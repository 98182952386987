@use '@angular/material' as mat;

@mixin app-card-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .card-common {
        padding: 16px;
        padding-top: 8px;
    }

    .card-common-header {

        app-card-header,
        .header {
            display: flex;
            flex-flow: row;
            align-items: center;
            position: relative;
            //padding: 8px 8px 8px 16px;

            margin-bottom: 16px;
            color: var(--app-secondary-text-color);

            h2 {
                margin: 0;
                padding: 8px;
                font-size: 1.3em;

                font-weight: normal;
                font-style: italic;
            }

            >mat-icon {
                font-size: 28px;
                height: 28px;
                width: 28px;
            }

            .icon-pos-right-side {
                position: absolute;
                right: 0;
                width: 20px;
                height: 20px;
                text-align: center;
                background: grey;
                color: var(--app-background-900-contrast);
                border-radius: 50%;
                padding: 2px;
                cursor: pointer;
            }
        }

    }

    // This is the place where we generally put the card main buttons.
    .card-subheader {
        display: inline-flex;
        flex-flow: row wrap;
        flex-basis: auto;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 16px;

        button {
            margin-left: 16px;
            margin-bottom: 16px;
            margin-right: unset;
        }
    }

    .card-navigation {
        @extend .card-common;
        @extend .card-common-header;

        box-sizing: border-box;

        margin: 16px 0 0 16px;

        background: var(--app-card-background-color);
        border-radius: 4px;
        box-shadow: 0 0 20px 1px rgba(#000, 0.1);

        display: flex;
        flex-flow: column;
        position: sticky;
        top: 80px;
    }

    .card-container {
        @extend .card-common;

        z-index: 3;
        display: flex;
        flex-flow: row wrap;

        box-sizing: border-box;

        width: 100%;
        max-width: 960px;
        //margin: 0 auto;

        @include breakpoint-lt("sm") {
            padding: 8px;
        }

        .card {
            flex: 1 1 auto;
            margin: 8px;
            padding: 24px;
            background: var(--app-card-background-color);
            border-radius: 4px;
            box-shadow: 0 0 20px 1px rgba(#000, 0.1);
            box-sizing: border-box;
            position: relative;

            @include breakpoint-lt("sm") {
                padding: 12px;
                margin: 4px;
            }

            @include breakpoint-lt("sm") {
                padding: 12px;
                margin: 4px;
            }

            @extend .card-common-header;


            >h3 {
                margin: 0;

                font-size: 0.8em;
                color: var(--app-text-color);
                font-weight: 600;
            }

            .row {
                display: flex;
                flex-flow: row wrap;

                margin-bottom: 16px;
                color: var(--app-secondary-text-color);

                h2 {
                    margin: 0;
                    padding: 8px;
                    font-size: 1.3em;

                    font-weight: normal;
                    font-style: italic;
                }

                >mat-icon {
                    font-size: 28px;
                    height: 28px;
                    width: 28px;
                }
            }

            >h3 {
                margin: 0;

                font-size: 0.8em;
                color: var(--app-text-color);
                font-weight: 600;
            }

            .row {
                display: flex;
                flex-flow: row wrap;

                >div {
                    padding: 8px;
                }
            }

            .actions {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                padding-top: 16px;
            }
        }

        .card-half {
            flex-basis: 40%;
        }

        .card-full {
            flex-basis: 100%;
            overflow: hidden;
        }
    }

    .design-card-title {
        grid-area: title;

        font-family: var(--mat-card-title-text-font);
        line-height: var(--mat-card-title-text-line-height);
        font-size: var(--mat-card-title-text-size);
        letter-spacing: var(--mat-card-title-text-tracking);
        font-weight: var(--mat-card-title-text-weight);

        @include apply-text-overflow-ellipsis();
    }

    .design-card-subtitle {
        grid-area: subtitle;

        color: var(--mat-card-subtitle-text-color);
        font-family: var(--mat-card-subtitle-text-font);
        line-height: var(--mat-card-subtitle-text-line-height);
        font-size: var(--mat-card-subtitle-text-size);
        letter-spacing: var(--mat-card-subtitle-text-tracking);
        font-weight: var(--mat-card-subtitle-text-weight);

        @include apply-text-overflow-ellipsis();
    }

    .design-card-content {
        grid-area: content;
        overflow: hidden;
    }

    .design-card-header {
        grid-area: header;
    }

    .design-card-footer {
        grid-area: footer;
    }

    .design-card-image {
        grid-area: image;
    }

    .design-card-select {
        grid-area: select;
    }

    .clickable-card {
        mat-card:hover {
            box-shadow: var(--mdc-protected-button-hover-container-elevation-shadow);
            cursor: pointer;
        }
    }
    .not-clickable-card {
        .mdc-card {
            --mdc-protected-button-hover-container-elevation-shadow: none;
            > .mat-ripple-element {
                --app-mat-ripple-element--display: none;
            }
        }
    }
}
