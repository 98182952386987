@import "./layout";

span.spacer {
    flex: 1 1 auto;
}

span.gap {
    display: block;
    height: var(--app-container-gap);
}

div.spacer {
    flex: 1 1 auto;
}

// Evenly distribute spaces for the buttons except if a button needs more spaces others buttons will be shrinked to allow more space for the large button.
// Must be used with a parent display: flex.
.child-fullwidth-distribution>* {
    flex-grow: 1; // Permet aux boutons de grandir pour remplir l'espace supplémentaire
    flex-shrink: 1; // Permet aux boutons de rétrécir si nécessaire
    flex-basis: auto; // La taille initiale du bouton est basée sur son contenu
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

// If you want your elements to be completely even, you can set flex-basis: 0. This will set the flex basis to 0 and then any remaining space (which will be all space since all basises are 0) will be proportionally distributed based on flex-grow.
// Source: https://stackoverflow.com/questions/25066214/flexbox-not-giving-equal-width-to-elements
.grow-equally-flex {
    flex-grow: 1;
    flex-basis: 0;
}

.centered-flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Use a collapsed column to break to a new column */
.break-column {
    flex-basis: 100%;
    width: 0;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-default-gap {
    gap: var(--app-container-gap);
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-row-responsive {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.vertical-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: flex-start; // Prevents horizontal stretching
}

.middle-align {
    @extend .vertical-align;
    text-align: center;
    align-items: center;
}


.full-width-distributed-row {
    @extend .flex-row;
    @extend .flex-default-gap;
    @extend .full-width;
    @extend .child-fullwidth-distribution
}

.child-full-width-distributed-row>* {
    @extend .flex-row;
    @extend .flex-default-gap;
    @extend .full-width;
    @extend .child-fullwidth-distribution
}

.no-overflow-x {
    overflow-x: hidden;
}

// Because heading (ex.: <h1>, <h2>, etc..) already adding margin but for the first element of a component (in some context we don't whant this extra space since the parent container already managing space between components.
.no-heading-margin-top {
    margin-top: 0px;
}


// Class that maybe needed for the intermediate parent to have a verticale scrollable area.
.scrollable-vertical-parent {
    @extend %scrollable-vertical-parent;
}

.scrollable-area {
    overflow: auto;
}

.no-margin {
    margin: 0px;
}

.no-padding {
    padding: 0px;
}

// Remove margin when the gap is set.
// Elements with default margins that interfere with the gap.
.flex-column.flex-default-gap {
    @include remove-default-margins();
}

.app-menu-container {
    padding: var(--app-contextual-menu-padding);
}



.app-container {
    padding: var(--app-container-padding);
    gap: var(--app-container-gap);

    &.full-height {
        height: calc(100% - 2 * var(--app-container-padding));
    }
}

.flex-column.app-menu-container {
    mat-divider {
        margin-left: calc(-1 * var(--app-contextual-menu-padding));
        width: calc(100% + 2 * var(--app-contextual-menu-padding));
    }
}

.flex-column.app-container {
    mat-divider {
        margin-left: calc(-1 * var(--app-container-padding));
        width: calc(100% + 2 * var(--app-container-padding));
    }
}

.app-options-highlight {
    margin-top: 15px;
    margin-bottom: 15px;
}