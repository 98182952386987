@import "./page";

.app-page--common {
    
    max-width: 100%;

    > app-page-header-card {
        padding-left: var(--app-page-content--padding);
        padding-right: var(--app-page-content--padding);
    }

    > app-page-header2-card {
        padding-left: var(--app-page-content--padding);
        padding-right: var(--app-page-content--padding);
    }
}

.app-page--normal-page {
    @extend .app-page--common;

    min-height: 100%;
    min-height: 100dvh;
    min-height: calc(100vh - env(safe-area-inset-bottom));
}

.app-page--centered-page {
    @extend .app-page--common;

    min-height: 100%;
    min-height: 100dvh;
    min-height: calc(100vh - env(safe-area-inset-bottom));

    align-items: center;

    .app-page--header {
        width: 100%;
    }

    .app-page--footer {
        width: 100%;
    }
}

.app-page--centered-full-width-page {
    @extend .app-page--centered-page;
}

.app-page--full-page {
    @extend .app-page--common;

    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    min-height: 0px;
}

.app-page--common-content {
    padding: var(--app-page-content--padding);
    gap: var(--app-page-content--gap);
}

.app-page--normal-content {
    @extend .app-page--common-content;

    display: var(--app-page-content--display);
    flex-direction: var(--app-page-content--flex-direction);
    width: calc(100% - (2 * var(--app-page-content--padding)));
    max-width: var(--app-page-content--max-width);
}

.app-page--centered-content {
    @extend .app-page--common-content;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
}

.app-page--centered-full-width-content {
    @extend .app-page--centered-content;

    width: calc(100% - (2 * var(--app-page-content--padding)));
}


.app-page--full-content {
    @extend .app-page--common-content;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    min-height: 0px;
}

.app-page--header {

}

.app-page--footer {

}

.app-page-header-card-title {}

.app-page-header-card-subtitle {
    color: var(--app-page-header-card--subtitle-text-color, var(--mat-card-subtitle-text-color));
}

.app-page-header-card-help {}

.app-page-header-card-content {}


.app-default-page-header-card {
    --app-design-card--grid-template-areas: 'title title content'
    'subtitle subtitle content';
    
    @include create-page-header-card($verticalCenterAll: false, $set-default-area: false);
}

.app-page-header-left-card {

}

.app-page-header-right-card {
    --app-design-card--grid-template-areas: var(--app-page-header-right-card--grid-template-areas);
    --app-design-card--grid-template-rows: var(--app-page-header-right-card--grid-template-rows);
    --app-design-card--grid-template-columns: var(--app-page-header-right-card--grid-template-columns);
}

app-page-header2-card {
    margin-bottom: 40px;
}