/////////////////////
// Classes to be used with the Tooltip directive:
// https://www.npmjs.com/package/ng2-tooltip-directive
// https://www.npmjs.com/package/ng2-tooltip-directive-major-angular-updates
// https://ng-bootstrap.github.io/#/components/popover/api
/////////////////////

.angular-tooltip {
    text-align: left !important;
    font-size: 14px !important;
    background-color: grey !important;

    &:after {
        border-color: transparent transparent transparent grey !important;

    }
}
