@import "common/layout";

@function get-shape-size($type) {
    @if($type == extra-small) {
        @return 22px;
    }
    @else if($type == small) {
        @return 36px;
    }
    @else if($type == medium) {
        @return 60px;
    }
    @else if($type == large) {
        @return 75px;
    }
    @else if($type == extra-large) {
        @return 96px;
    }
}

@mixin apply-shape-size($type) {
    $size: get-shape-size($type);
    @include apply-fixed-size($size, $size);

    --app-mdc-material-icon-size: calc(#{$size} - 10px);
}