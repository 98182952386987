/////////////////////
// Classes to be used with the Tabs component:
// https://material.angular.io/components/tabs/overview
/////////////////////

.app-mat-tab-group {
    .mat-mdc-tab-body-content {
        @include flex-setup(column);
    }

    mat-tab {
        &.form-tab {
            @extend .app-form-container;
        }
        
    }

    &.centered-tab-group {
        .mat-mdc-tab-body-wrapper {
            justify-content: center;
        }
    }

    &.medium {
        max-width: var(--app-form-container--max-width);
        mat-tab-body {
            max-width: var(--app-form-container--max-width);
        }
    }

    &.full-height {
        .mat-tab-body-wrapper, // angular < 16
        .mat-mdc-tab-body-wrapper // angular >= 16
        {
            flex-grow: 1;
        }
    }
}

.tab-header {
    display: flex;
    flex-direction: row;
    gap: var(--app-container-gap);
    align-items: center;
    flex-wrap: wrap;

    h2 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.tab-template-label {
    display: flex;
    flex-direction: row;
    gap: var(--app-container-gap);
    align-items: center;
}


.app-ng-tab--full-height {
    .mat-mdc-tab-body-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1;
        min-height: 0px;
    }
}

// For tabs that doesn't looks good where there are too large.
.app-tab--medium {
    max-width: var(--app-form-container--max-width);
}

.app-tab--full-width {
    width: 100%;
}

.mat-mdc-tab-body {
    padding-top: var(--app-container-gap);
}