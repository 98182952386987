@mixin app-nav-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);


  a.light-link {

        color: #fff;
        cursor: pointer;


        &:hover {
            text-decoration: underline;
        }
  }

  /**
     * SIDE NAV
     */
  app-main-nav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 60;

    display: flex;
    flex-flow: column;
    overflow: hidden;

    .container {
      width: var(--sidebar-width);
      height: 100vh;
      z-index: 2;
      position: absolute;

      
      //border-right: solid 1px map-get($background, background);
      

      display: flex;
      flex-flow: column;



      .menus {
        overflow: auto;
      }

      .org-context {
        padding: 8px;
        display: flex;
        flex-flow: column;
        background-color: var(--app-nav-toolbar-background-color);
        
        label {
            font-size: 0.7em;
            display: none;
        }

        .current-org {
            box-shadow: 0 0 8px 2px rgba(#000, 0.25) inset;
            padding: 4px;
            border-radius: 4px;
            border: 1px solid var(--app-divider-color);
            display: flex;
            flex-flow: row;
            align-items: center;
            background-color:  var(--app-primary-dark-background-color);
            color: var(--app-background-900-contrast);

            >mat-icon {
                margin: 0 12px;
            }

            .name {
                padding: 12px 12px 12 0;
                flex: 1 1 auto;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
                max-height: 40px;

                &:hover {
                    text-decoration: underline;
                }
                //font-size: 1.2em;
            }

        }
      }

      /*
      .header {
        box-sizing: border-box;
        position: relative;
        padding: 8px;
        height: 120px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        .title {
          font-size: 1.3em;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 10px 0;
          font-weight: bold;
        }

        .actions {
          display: flex;
          flex-flow: row;
          //width: 100%;
        }

        background-color: map-get($primary, 200);
        background-image: radial-gradient(circle, map-get($accent, 300), map-get($accent, 700));
      }*/
    }
  }

  /**
     * TOOLBAR
     */
  app-toolbar {
    position: sticky;
    top: 0;
    z-index: 22;
    // height: 64px;

    display: block;
    flex-flow: column;
    width: 100%;
    box-sizing: border-box;
    //box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.5);

    /*background-image: linear-gradient(
      45deg,
      mat-color($primary, 900),
      mat-color($primary, 400),
      mat-color($accent, 700)
    );*/
    //background-color: map-get($primary, 700);
    color: #fff;

    .main-bar {
      display: flex;
      flex-flow: row;
      height: 64px;
      background-color: var(--app-nav-toolbar-background-color);
      padding: 0 12px;
      overflow: hidden;
    }

    .extra-bar {
      background-color: var(--app-nav-toolbar-background-color);
      //overflow: hidden;

      .contents {
        padding: 16px 16px 16px 24px;
        box-sizing: border-box;
        max-height: 100%;
        &:empty {
          display: none;
        }
      }
    }

    .offset-bar {
      display: block;
      height: 32px;
      min-height: 32px;
      background-color: var(--app-nav-offsetbar-background-color);
    }

    .logo {
      display: inline-block;
      padding: 0 16px 0 0;
      user-select: none;

    }

    .title {
      padding: 4px 16px;
      border-left: 1px solid rgba(#fff, 0.5);

      a {
        text-decoration: none;
        color: #fff;
      }

      @include breakpoint-lte("sm") {
        display: none;
      }
    }
  }

  .mock-toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    padding: 0 12px;
    background-color: var(--app-nav-toolbar-background-color);
    display: flex;
    color: #fff;

    .prefix {
      display: flex;
      align-items: center;
    }

    .toolbar-content {
      flex: 1 1 auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .logo {
      display: inline-block;
      padding: 0 16px;
      font-size: 1.6em;
      user-select: none;
      letter-spacing: -0.05em;
      padding-bottom: 6px;
    }
  }

  .tools {
    display: flex;
    padding: 0 0 12px 0;

    .search {
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
     */
      .mat-form-field-wrapper {
        margin: 0 !important;
        padding: 0 !important;
      }
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
     */
      .mat-form-field-infix {
        padding: 8px 0 !important;
        border-top: 0.25em solid transparent;
      }
    }
  }
}

.navigation {
	width: 100%;
    display: contents;
	a {
		display: flex;
    align-items: center;
		padding: 0 10px;
		color: white;
		font-weight: 500;
		cursor: pointer;
		text-decoration: none;
		font-size: 15px;
	}
	.hide-navigation {
		display: none;
	}
}
