@import "./card";
@import "common/layout";
@import "common/text";
@import "mat-m3-shared";

@mixin create-page-header-card($verticalCenterAll: false, $padding: null, $set-default-area: true) {

    --app-design-card--color: var(--app-page-header-card--color, var(--app-design-card--color));
    --app-design-card--subtitle-text-color: var(--app-page-header-card--subtitle-text-color, var(--app-design-card--subtitle-text-color));

    @include create-default-card(null, null, $verticalCenterAll, $padding, $set-default-area);

    min-height: 140px;

    width: calc(100% - (2 * var(--app-page-content--padding)));
    max-width: var(--app-page-header-card--max-width);

    color: var(--app-page-header-card--color, var(--app-design-card--color));
    --app-mdc-card-content--color: var(--app-page-header-card--color, var(--app-design-card--color));
    --mat-card-subtitle-text-color: var(--app-page-header-card--subtitle-text-color, var(--app-design-card--subtitle-text-color));

    --app-design-card--padding-left: var(--app-page-header-card--padding-left);
    --app-design-card--padding-right: var(--app-page-header-card--padding-right);
    --app-design-card--padding-top: var(--app-page-header-card--padding-top);
    --app-design-card--padding-bottom: var(--app-page-header-card--padding-bottom);

    design-card-title {
        @include apply-vertical-align(bottom, $flex-direction: 'row');
        @include apply-md-typography(headline-medium);
        max-height: var(--app-page-header--title-height);
        min-height: var(--app-page-header--title-height);
    }

    page-header-card-extended-title {
        @include apply-md-typography(headline-medium);

        .app-title {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            letter-spacing: inherit;

            text-wrap: nowrap;
        }

        min-height: var(--app-page-header--title-height);
        max-height: var(--app-page-header--title-height);
    }

    design-card-subtitle {
        @include apply-vertical-align('top', $flex-direction: 'row');
        @include apply-md-typography(body-medium);
        @include apply-secondary-text();

        word-wrap: break-word;
        text-wrap: wrap;
    }

    design-card-content {
        @include apply-vertical-align(top);
        align-items: flex-end;
    }

    design-card-header {
        @include apply-vertical-align();
    }

    design-card-footer {
        @include apply-vertical-align();
    }

    --mdc-outlined-card-container-shape: 24px;

    --app-design-card--column-gap: var(--app-page-header-card--column-gap);
    --app-design-card--row-gap: var(--app-page-header-card--row-gap);

}