/////////////////////
// Classes to be used with the Select component (mat-select):
// https://material.angular.io/components/select/overview
/////////////////////


// To make Angular select adapt to the content.
// Also put [panelWidth]="null" on the mat-select to make the dropdown adapt to the longest string.
.angular-select-fit-content-container {
    width: fit-content;
        
    .mat-mdc-select-value {
        margin-right: 5px;
    }
}

.app-angular-select-panel-container {
    height: min-content;
}