@use '@angular/material' as mat;
@import "common/breakpoints";

@mixin app-container-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .panel {
        background-color: var(--app-card-background-color);
        padding: 48px;
        border-radius: 4px;
        //box-shadow: 0 0 50px 1px rgba(0,0,0,0.2);
    }

    .error-container {
        box-sizing: border-box;
        margin: 0 0 12px 0;
        padding: 12px;
        //text-align: center;
        background: var(--app-error-background-color);
        display: flex;

        color: var(--app-error-text-color);
        border-radius: 4px;

        mat-icon {
            margin-right: 12px;
        }
    }

    .warning-container {
        box-sizing: border-box;
        margin: 0 0 12px 0;
        padding: 12px;
        //text-align: center;
        background: yellow;
        display: flex;

        color: black;
        border-radius: 4px;

        mat-icon {
            margin-right: 12px;
        }
    }

    .chip {
        margin: -2px 4px 0;
        background: var(--app-secondary-text-color);
        color: #fff;
        padding: 2px 4px 3px;
        font-size: 12px;
        border-radius: 4px;

        &.primary-dark {
            background: var(--app-primary-dark-background-color);
        }

        &.accent-dark {
            background: var(--app-accent-dark-background-color);
        }

        &.accent-warn {
            background: #de2f2f;
        }

        &.accent-purple {
            background: #46117d !important;
        }

        &.light-dark{
            background: rgb(206 164 0);
        }
    }


    .flex-gap {
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
    }
      
    .flex-gap > div {
        .input-field {
            width: 100%;
        }
    }

    @include breakpoint-gte('md') {
        .flex-gap > div {
            width: 48%;
        }
    }

    @include breakpoint-lte('md') {
        .flex-gap > div {
            width: 100%;
        }  
    }
}
