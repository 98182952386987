@use "@angular/material" as mat;

/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';
@import "./custom-bootstrap.scss";


@import "./theme/common/layout-globals";
@import "./theme/common/text-globals";
@import "./theme/previous-theme/palette";
@import "./theme/settings";
@import "./theme/previous-theme/nav";
@import "./theme/previous-theme/container";
@import "./theme/previous-theme/card";
@import "./theme/matfix";
@import "./theme/common/breakpoints";
@import "./theme/common/form-globals";


@import "./theme/angular-components/button-toggle-globals.scss";
@import "./theme/angular-components/dialog-globals";
@import "./theme/angular-components/form-globals";
@import "./theme/angular-components/icon-globals";
@import "./theme/angular-components/list-globals";
@import "./theme/angular-components/popover-globals";
@import "./theme/angular-components/select-globals";
@import "./theme/angular-components/stepper-globals";
@import "./theme/angular-components/tab-globals";
@import "./theme/angular-components/tooltip-globals";

@import "./theme/applogic-components/card-globals";
@import "./theme/applogic-components/dialog-globals";
@import "./theme/applogic-components/list-globals";
@import "./theme/applogic-components/property-list-globals";
@import "./theme/applogic-components/page-globals";
@import "./theme/applogic-components/shape-globals";
@import "./theme/applogic-components/text-globals";

//@import "./theme/mat-core";
@import "./theme/mat-m3";
@import "./palettes/test-palette";
@import "./palettes/test-palette1";

@import "./styles/_angular-material-override.scss";

@include mat.core();

:root {
    @include app-settings-theme($theme);
}

.starting-theme,
.light-theme {
    font-family: $global-font-family, "formular", Arial, sans-serif;
    // The following doesn't work. It doesn't return the configured font.
    // font-family: mat.get-theme-typography($dark-theme, body-large, font-family);

  // Apply the base theme at the root, so it will be inherited by the whole app.
    @include mat.all-component-themes($light-theme);
    @include mat.all-component-typographies($light-theme);

    @include mat.color-variants-backwards-compatibility($light-theme);
    // @include mat.all-component-colors($light-theme);

    @include apply-added-angular-material-variables();
    @include app-settings-theme($light-theme);

    @include apply-m3-theme($light-theme);

    @include app-angular-material-override($light-theme);
}

.dark-theme {
    font-family: $global-font-family, "formular", Arial, sans-serif;
    // The following doesn't work. It doesn't return the configured font.
    // font-family: mat.get-theme-typography($dark-theme, body-large, font-family);

  // Apply the base theme at the root, so it will be inherited by the whole app.
    @include mat.all-component-themes($dark-theme);
    
    @include mat.color-variants-backwards-compatibility($dark-theme);
    // @include mat.all-component-colors($dark-theme);

    @include apply-added-angular-material-variables();
    @include app-settings-theme($dark-theme);

    @include apply-m3-theme($dark-theme);

    @include app-angular-material-override($dark-theme);
}

.light-dynamic-theme {
    
    // @include apply-dynamic-palette-variables();
    
    @include apply-dynamic-palette-variables-from-palette(dynamic-primary-palette, $test-palette, mat.$violet-palette);

    font-family: $global-font-family, "formular", Arial, sans-serif;
    // The following doesn't work. It doesn't return the configured font.
    // font-family: mat.get-theme-typography($dark-theme, body-large, font-family);

  // Apply the base theme at the root, so it will be inherited by the whole app.
    @include mat.all-component-themes($light-dynamic-theme);
    @include mat.all-component-typographies($light-dynamic-theme);

    @include mat.color-variants-backwards-compatibility($light-dynamic-theme);
    // @include mat.all-component-colors($light-theme);

    @include apply-added-angular-material-variables();
    @include app-settings-theme($light-dynamic-theme);

    @include apply-m3-theme($light-dynamic-theme);

    @include app-angular-material-override($light-dynamic-theme);
}

.dark-dynamic-theme {

    @include apply-dynamic-palette-variables();

    @include apply-dynamic-palette-variables-from-palette(dynamic-primary-palette, $test-palette, mat.$violet-palette);
    
    font-family: $global-font-family, "formular", Arial, sans-serif;
    // The following doesn't work. It doesn't return the configured font.
    // font-family: mat.get-theme-typography($dark-theme, body-large, font-family);

  // Apply the base theme at the root, so it will be inherited by the whole app.
    @include mat.all-component-themes($dark-dynamic-theme);
    
    @include mat.color-variants-backwards-compatibility($dark-dynamic-theme);
    // @include mat.all-component-colors($dark-theme);

    @include apply-added-angular-material-variables();
    @include app-settings-theme($dark-dynamic-theme);

    @include apply-m3-theme($dark-dynamic-theme);

    @include app-angular-material-override($dark-dynamic-theme);
}

.light-theme-no-comp {
    font-family: $global-font-family, "formular", Arial, sans-serif;
    // The following doesn't work. It doesn't return the configured font.
    // font-family: mat.get-theme-typography($dark-theme, body-large, font-family);

  // Apply the base theme at the root, so it will be inherited by the whole app.
    @include mat.all-component-themes($light-theme);
    @include mat.all-component-typographies($light-theme);

    @include apply-added-angular-material-variables();
    @include app-settings-theme($light-theme);

    @include apply-m3-theme($light-theme);

    @include app-angular-material-override($light-theme);
}

.dark-theme-no-comp {
    font-family: $global-font-family, "formular", Arial, sans-serif;
    // The following doesn't work. It doesn't return the configured font.
    // font-family: mat.get-theme-typography($dark-theme, body-large, font-family);

  // Apply the base theme at the root, so it will be inherited by the whole app.
    @include mat.all-component-themes($dark-theme);
    
    @include apply-added-angular-material-variables();
    @include app-settings-theme($dark-theme);
    
    @include apply-m3-theme($dark-theme);

    @include app-angular-material-override($dark-theme);
}

.test-theme {
}


/*
.previous-theme {
    font-family: "formular", Arial, sans-serif;
    // The follow doesn't work. It doesn't return the configured font.
    // font-family: mat.get-theme-typography($previous-theme, body-large, font-family);
    // letter-spacing: mat.get-theme-typography($previous-theme, body-large, letter-spacing);

    @include mat.all-component-themes($previous-theme);
    @include apply-previous-theme($previous-theme);

    @include apply-added-angular-material-variables();

    @include app-matfix-theme($previous-theme);

    @include app-angular-material-override($previous-theme);

    @include app-nav-theme($previous-theme);
}*/

html .no-theme {

}


html,
body {
    margin: 0;
    padding: 0;
    color: var(--app-text-color);
    background-color:  var(--app-background-color);
    overflow: hidden;
    font-size: 16px;
}



router-outlet.dashboard-outlet ~ * {
    //position: absolute;
    /*width: 100%;
    height: 100%;
    top: 0;
    left: 0;

	display: block;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
    overflow: auto;

    display: block;
    min-height: calc(100vh + 80px);
    */
}

@include app-container-theme($theme);
@include app-card-theme($theme);
@include app-text-theme($theme);
@include app-list-theme($theme);

app-login-page,
app-register-page,
app-not-found-before-auth {
    background: var(--app-nav-toolbar-background-color);
    //background: linear-gradient(0deg, mat-color($theme-primary, 700), mat-color($theme-primary, 300), mat-color($theme-primary, 700));
}




.hidden-field {
    display: none !important;
}

.invisible-field {
    visibility: hidden;
    display: block !important;
}




// This give the ability to hide all promo code sections
// until the feature will be ready to use.
.hidden-feature {
    display: none !important;
}

@media print {
    app-root > * { display: none; }
    app-root app-print-layout { display: block; }
    body * {
        visibility:hidden !important;
      }
      #printable, #printable * {
        visibility:visible !important;
      }
      #printable {
        position:absolute;
        left:0;
        top:0;
      }
}





// regular style toast
@import 'ngx-toastr/toastr';

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
// @import 'ngx-toastr/toastr-bs4-alert';



.no-selection {
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}



.game-progress-list {
    overflow-x: auto;
    scroll-behavior: smooth;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    mat-table {
        width: max-content;
    }
}

// Fonction pour déterminer si la couleur est claire ou foncée
@function is-light($color) {
    // Calcule la luminance de la couleur
    $luminance: (
        red($color) * 0.299 + green($color) * 0.587 + blue($color) * 0.114) / 255;
    // Retourne vrai si la luminance est supérieure à un seuil de luminosité (par exemple, 0.5)
    @return $luminance >0.5;
}

// Fonction pour obtenir une couleur de texte contrastée
@function contrast-color($background-color) {
    @if is-light($background-color) {
        // Si la couleur d'arrière-plan est claire, retourne une couleur foncée pour le texte
        @return #000000; // Noir
    }

    @else {
        // Si la couleur d'arrière-plan est foncée, retourne une couleur claire pour le texte
        @return #FFFFFF; // Blanc
    }
}

.aligned-with-not-aligned {
    
}